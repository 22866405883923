import React, {Component} from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../actions';

//import { LinkContainer } from 'react-router-bootstrap';
import {Container, Button, Row, Col} from 'react-bootstrap';
import Orders from './orders';

class UserShippingAddress extends Component{
	render(){
		let {name, address1, address2, city, state, zip, email} = this.props;
		return (
			<div style={{fontSize: '.8em'}}>
			{email && (<div>{email}</div>)}
			{name && (<div>{name}</div>)}
			{address1 && (<div>{address1}</div>)}
			{address2 && (<div>{address2}</div>)}
			{city && (<div>{city}</div>)}
			{state && (<div>{state}</div>)}
			{zip && (<div>{zip}</div>)}
			</div>
		);
	}
}

class Shipping extends Component{
	constructor(props){
		super(props);
		if( props.shippingAddress){
			let {email, name, address1, address2, city, state, zip} = props.shippingAddress;

			this.state = {
				showForm: false,
				email: email || '',
				name: name || '',
				address1: address1 || '',
				address2: address2 || '',
				city: city || '',
				state: state || '',
				zip: zip || ''
			}
		}else{
			this.state = {
				showForm: false
			}
		}
	}

	componentWillMount(){
		// this.setState({
		// 	showForm: false,
		// 	email: props.email || '',
		// 	name: props.name || '',
		// 	address1: props.address1 || '',
		// 	address2: props.address2 || '',
		// 	city: props.city || '',
		// 	state: props.state || '',
		// 	zip: props.zip || ''
		// })
	}

	_saveShipping(){
		let {uid} = this.props.userData;
		let {email,address1,address2,city,state,zip,name} = this.state;

		this.props.updateShippingAddress(uid,{address1,address2,city,state,zip,name,email})
		this.setState({showForm: false});
	}

	_showEdit(){
		this.setState({showForm: true})
	}

	render(){
		// console.log('our props and state');
		// console.log(this.props);
		// console.log(this.state);
		let {shippingAddress} = this.props;
		if(!shippingAddress){
			shippingAddress = {};
		}
		return (
			<Container>
				<Row>
					<Col>
						<h6>Shipping Info</h6>
					</Col>
				</Row>

				<Row>
					<Col xs={12} sm={12} md={6} lg={5} xl={5}>
					{(!shippingAddress || this.state.showForm) && (
					<form>
					  <div className="form-group bmd-form-group bmd-form-group-sm">
					    <label htmlFor="email" className="bmd-label-floating">Email</label>
					    <input type="text" 
					    className="form-control" 
					    id="email" 
					    defaultValue={shippingAddress.email || ''}
					    onChange={(txt)=>{this.setState({email:txt.target.value})}} />
					  </div>
					  <div className="form-group bmd-form-group bmd-form-group-sm">
					    <label htmlFor="name" className="bmd-label-floating">Name</label>
					    <input type="text" 
					    className="form-control" 
					    id="name" 
					    defaultValue={shippingAddress.name || ''}
					    onChange={(txt)=>{this.setState({name:txt.target.value})}} />
					  </div>
					  <div className="form-group bmd-form-group bmd-form-group-sm">
					    <label htmlFor="address1" className="bmd-label-floating">Address</label>
					    <input type="text" 
					    className="form-control" 
					    id="address1" 
					    defaultValue={shippingAddress.address1 || ''}
					    onChange={(txt)=>{this.setState({address1:txt.target.value})}} />
					  </div>
					  <div className="form-group bmd-form-group bmd-form-group-sm">
					    <label htmlFor="address2" className="bmd-label-floating">Address 2 (ie. Apt, Suite, etc)</label>
					    <input type="text" 
					    className="form-control" 
					    id="address2" 
					    defaultValue={shippingAddress.address2 || ''}
					    onChange={(txt)=>{this.setState({address2:txt.target.value})}} />
					  </div>
					  <div className="form-group bmd-form-group bmd-form-group-sm">
					    <label htmlFor="city" className="bmd-label-floating">City</label>
					    <input type="text" 
					    className="form-control" 
					    id="city" 
					    defaultValue={shippingAddress.city || ''}
					    onChange={(txt)=>{this.setState({city:txt.target.value})}} />
					  </div>
					  <div className="form-group bmd-form-group bmd-form-group-sm">
					    <label htmlFor="state" className="bmd-label-floating">State</label>
					    <input type="text" 
					    className="form-control" 
					    id="state" 
					    defaultValue={shippingAddress.state || ''}
					    onChange={(txt)=>{this.setState({state:txt.target.value})}} />
					  </div>
					  <div className="form-group bmd-form-group bmd-form-group-sm">
					    <label htmlFor="zip" className="bmd-label-floating">Zip</label>
					    <input type="text" 
					    className="form-control" 
					    id="zip" 
					    defaultValue={shippingAddress.zip || ''}
					    onChange={(txt)=>{this.setState({zip:txt.target.value})}} />
					  </div>
					  <button type="button" className="btn btn-raised btn-success" onClick={this._saveShipping.bind(this)}>Save</button>
					</form>
					)}

					{ (shippingAddress && !this.state.showForm) && (
						<UserShippingAddress {...this.props.shippingAddress} />
					)}

					{ ((!shippingAddress || !shippingAddress.state) && !this.state.showForm) && (
						<button className="btn btn-success btn-raised" onClick={this._showEdit.bind(this)}>Add Address</button>
					)}
					</Col>

					{shippingAddress && shippingAddress.state && !this.state.showForm && (
					<Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<Button variant="secondary" size="sm" style={{marginTop:'24px'}} onClick={this._showEdit.bind(this)}>Edit Address</Button>
					</Col>
					)}
				</Row>
			</Container>
		);
	}
}

class Profile extends Component{

	componentWillMount(){
	}

	render(){
		return (
			<div style={{marginTop:'8px'}}>
				<Row>
					<Col xs={8} sm={8} md={6} lg={5}>
						<h5>Your Account</h5>
					</Col>
					<Col xs={4} sm={4} md={6} lg={6}>
						<Button size="sm" variant="outline-danger" onClick={this.props.signOut}>Sign Out</Button>
					</Col>
				</Row>
				<Row>
					<Shipping {...this.props} />
				</Row>
				<Row>
					<Orders {...this.props} />
				</Row>
			</div>
		);
	}
}

function mapStateToProps(state){
  return {
   userData: state.userData,
   shippingAddress: state.shippingAddress,
   finished: state.finished,
   invoices: state.invoices
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Profile);