import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

class WelcomePage extends React.Component{
	render(){
		return (
			<Container>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} style={{marginTop:'24px'}}>
					  <LinkContainer to="/create">
						<Button>Create a Planket&nbsp;
							<img src={require('../img/pillow_square_small.jpg')} height="24" alt="Planket.shop Logo" />
						</Button>					
						</LinkContainer>
					</Col>

					<Col xs={12} sm={12} md={12} lg={12} style={{marginTop:'24px'}}>
						<LinkContainer to="/account">
							<Button variant="secondary">Your Orders & Account</Button>
						</LinkContainer>
					</Col>

				</Row>
			</Container>
		);
	}
}

export default WelcomePage;