import React, {Component} from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../actions';

import firebase from 'firebase/app';
import 'firebase/auth';

import Profile from './profile';

import {Container,Row, Col, Button,Alert} from 'react-bootstrap';

class Signup extends Component{
	state = {
		email: '',
		password: '',
		passwordConfirm: '',
		emailError: null,
		passwordError: null,
		passwordConfirmError: null
	};

	_createUser(){
		let {email, password} = this.state;

		firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
		  // Handle Errors here.
		  if(error){
  			let {code, message} = error;
  			// console.log('problem signing up: ')
  			// console.log(code);
  			// console.log(message);

  		}else{
  			// console.log('attempting to link from anon account');
	  		var credential = firebase.auth.EmailAuthProvider.credential(email, password);
	  		firebase.auth().currentUser.linkAndRetrieveDataWithCredential(credential).then(function(usercred) {
			  	var user = usercred.user;
			  	// console.log("Anonymous account successfully upgraded", user);
				}, function(error) {
			  	// console.log("Error upgrading anonymous account", error);
				});
			}

		});

	}

	_signup(){
		// console.log('signup pressed');
		let {email, password, passwordConfirm} = this.state;

		if(email.indexOf('@') < 0){
			this.setState({emailError: 'Please enter a valid email'});
			return;
		}else{
			this.setState({emailError: null})
		}

		if(email.length < 4){
			this.setState({emailError: 'Please enter a valid email'});
			return;
		}else{
			this.setState({emailError: null})
		}

		if(password.length < 6){
			this.setState({passwordError: 'Password must be atleast 6 characters'});
			return;
		}else{
			this.setState({passwordError: null})
		}

		if(password !== passwordConfirm){
			this.setState({passwordConfirmError: 'Password and Confirmation Password must match!'});
			return;
		}else{
			this.setState({passwordConfirmError: null})
		}

		this._createUser();
	}

	componentDidUpdate(){
	}

	render(){
		return (
			<div className="row">
				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<h4>Create Your Account:</h4>
				</Col>
				<Col xs={11} sm={8} md={6} lg={6} xl={5} className="col-lg-offset-3 col-md-offset-2 col-sm-offset-1">
				<form>
				  <div className="form-group">
				    <label htmlFor="exampleInputEmail1" className="bmd-label-floating">Email address</label>
				    <input type="email" className={'form-control ' + ((this.state.emailError) ? 'is-invalid': '')} id="exampleInputEmail1" onChange={(txt)=>{this.setState({email:txt.target.value})}} />
						{this.state.emailError && (<div className="invalid-feedback">{this.state.emailError}</div>)}
				  </div>
				  <div className="form-group">
				    <label htmlFor="exampleInputPassword1" className="bmd-label-floating">Password</label>
				    <input type="password" className={'form-control ' + ((this.state.passwordError) ? 'is-invalid': '')}  id="exampleInputPassword1" onChange={(txt)=>{this.setState({password:txt.target.value})}} />
				    {this.state.passwordError && (<div className="invalid-feedback">{this.state.passwordError}</div>)}
				  </div>
				  <div className="form-group">
				    <label htmlFor="exampleInputPassword2" className="bmd-label-floating">Confirm Password</label>
				    <input type="password"  className={'form-control ' + ((this.state.passwordConfirmError) ? 'is-invalid': '')}  id="exampleInputPassword2" onChange={(txt)=>{this.setState({passwordConfirm:txt.target.value})}} />
				    {this.state.passwordConfirmError && (<div className="invalid-feedback">{this.state.passwordConfirmError}</div>)}
				  </div>
				</form>
					<Row>
						<Col xs={12} sm={12} md={7} lg={7}>
							<button type="button" className="btn btn-primary btn-raised" style={{width:'100%'}} onClick={this._signup.bind(this)}>
								Create Your Account &nbsp;
								<i className="material-icons" style={{verticalAlign:'middle'}}>account_box</i>
								</button>
						</Col>
					</Row>
				</Col>

				<Col style={{marginTop:'12px'}} xs={11} lg={4} xl={5} md={6} sm={8} className="col-lg-offset-3 col-md-offset-2 col-sm-offset-1">
				<Alert variant="secondary">
					Already have an account at Planket.shop? <br />
					<Button onClick={this.props.showLogin.bind(this)} style={{marginTop:'8px',width:'100%'}} variant="secondary">Login Here <i className="material-icons" style={{verticalAlign:'middle'}}>exit_to_app</i></Button>
					</Alert>
				</Col>
			</div>
		);
	}
}

class Login extends Component{
	state = {
		email: '',
		password: '',
		emailError: null,
		passwordError: null,
	};

	_login(){
		let {email, password} = this.state;
		if((email.length < 4) || email.indexOf('@')<0 ){
			this.setState({emailError: true})
			return false;
		}else{
			this.setState({emailError: null})
		}

		if(password.length < 4){
			this.setState({passwordError: true})
			return false;
		}else{
			this.setState({passwordError: null})
		}

		firebase.auth().signInWithEmailAndPassword(email,password);
	}

	render(){
		return (
			<Row>
				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
					<h4>Login to your account:</h4>
				</Col>
				<Col xs={11} sm={8} md={7} lg={6} xl={5} className="col-lg-offset-3 col-md-offset-2 col-sm-offset-1">
					<form>
					  <div className="form-group">
					    <label htmlFor="exampleInputEmail1" className="bmd-label-floating">Email address</label>
					    <input type="email" className={'form-control ' + ((this.state.emailError) ? 'is-invalid': '')} id="exampleInputEmail1" onChange={(txt)=>{this.setState({email:txt.target.value})}} />
							{this.state.emailError && (<div className="invalid-feedback">Enter a valid email</div>)}
					  </div>
					  <div className="form-group">
					    <label htmlFor="exampleInputPassword1" className="bmd-label-floating">Password</label>
					    <input type="password" className={'form-control ' + ((this.state.passwordError) ? 'is-invalid': '')}id="exampleInputPassword1" onChange={(txt)=>{this.setState({password:txt.target.value})}} />
					  	{this.state.passwordError && (<div className="invalid-feedback">Invalid password</div>)}
					  </div>
					</form>
					<Row>
						<Col xs={12} sm={12} md={7} lg={7}>
							<button type="button" className="btn btn-primary btn-raised" style={{width:'100%'}} onClick={this._login.bind(this)}>
								Login &nbsp;
								<i className="material-icons" style={{verticalAlign:'middle'}}>exit_to_app</i></button>
						</Col>
					</Row>
				</Col>
				<Col style={{marginTop:'12px'}} xs={11} xl={4} lg={4} md={3} sm={12} className="col-lg-offset-3 col-md-offset-2 col-sm-offset-1">
					<Alert variant="secondary">
						Don't have an account yet? You can make one easily! <br />
					<Button variant="secondary" style={{marginTop:'8px',width:'100%'}} onClick={this.props.showSignup.bind(this)}>Create Account <i className="material-icons" style={{verticalAlign:'middle'}}>account_box</i></Button>
					</Alert>
				</Col>
			</Row>
		);
	}
}


class Account extends Component{
	state = {
		accountStep: 'signup'
	}
  
  _signOut(){
		firebase.auth().signOut().then(function() {
		  // console.log('user signed out of personal account... should go back to anonymous');
		}).catch(function(error) {
		  // An error happened.
		});
  }

	_showLogin(){
		this.setState({accountStep: 'login'});
	}
	_showSignup(){
		this.setState({accountStep: 'signup'});
	}

	render(){

		if(!this.props.userData.isAnonymous){
			return (<Container><Profile signOut={this._signOut.bind(this)} /></Container>);
		}else{

			if(this.state.accountStep === 'login'){
				return (<Container>
					<Login showSignup={this._showSignup.bind(this)} />
					</Container>
				);
			}

			if(this.state.accountStep === 'signup'){
				return (
					<Container>
					<Signup  showLogin={this._showLogin.bind(this)} />
					</Container>
				);
			}	
		}
	}
}


function mapStateToProps(state){
  return {
   userData: state.userData,
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Account);