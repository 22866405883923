import * as types from './types';

import firebase from 'firebase/app';
import 'firebase/database';

//dispatches state updates
export function updateUserData(userdata){
	return (dispatch,getState) =>{
		dispatch(setUserData(userdata));
	}
}


//update the various parts of the current draft state
export function updateDraftMaterial(material){
	return (dispatch, getState) =>{
		let {currentDraft} = getState();
		currentDraft.material = material;
		dispatch(setCurrentDraft(currentDraft));
	}
}

export function updateDraftDetail(detail){
	return (dispatch, getState) =>{
		let {currentDraft} = getState();
		currentDraft.detail = detail;
		dispatch(setCurrentDraft(currentDraft));	
	}
}

export function updateDraftSize(size){
	return (dispatch, getState) =>{
		let {currentDraft} = getState();
		currentDraft.size = size;
		dispatch(setCurrentDraft(currentDraft));		
	}
}

//get the available materials, details, and sizes from the database
export function fetchAvaiableMaterials(){
	return (dispatch, getState) =>{
		firebase.database().ref('materials').once('value')
			.then((materialsSnap)=>{
				let materialsObject = materialsSnap.val();
				let materialsArray = Object.keys(materialsObject).map( (k) => {
					materialsObject[k].id = k;
					return materialsObject[k]; 
				}).filter((m)=>{
					return (m.quantity > 0);
				})
				
				dispatch(setAvailableMaterials(materialsArray))
			},(err)=>{
				console.log('error pulling materials: ', err);
			})
	}
}

export function fetchAvaiableDetails(){
	return (dispatch, getState) =>{
		firebase.database().ref('detail').once('value')
			.then((detailsSnap)=>{
				let detailsObject = detailsSnap.val();
				let detailsArray = Object.keys(detailsObject).map( (k) => {
					detailsObject[k].id = k;
					return detailsObject[k]; 
				}).filter((d)=>{
					return (d.quantity > 0);
				})
				
				dispatch(setAvailableDetails(detailsArray))
			},(err)=>{
				console.log('error pulling details: ', err);
			})
	}
}

export function fetchAvaiableSizes(){
	return (dispatch, getState) =>{

	}
}

export function fetchMyFinished(){
	console.log('calling fetchMyFinished action');
	return (dispatch, getState)=>{
		let {userData} = getState();
		firebase.database().ref('finished')
			.child(userData.uid)
			.once('value')
			.then((finSnap)=>{
				let fin = finSnap.val();
				
				if(fin){
					let finished = Object.keys(fin).map(k => fin[k]);
					dispatch( setFinished(finished) );
				}else{
					dispatch( setFinished([]));
				}

			})
	};
}

export function fetchMyInvoices(){
	return (dispatch, getState)=>{
		let {userData} = getState();
		firebase.database().ref("invoices")
			.child(userData.uid)
			.once('value')
		.then((invSnap)=>{
			let invs = invSnap.val();
			
			if(invs){
				let invoices = Object.keys(invs).map(k => invs[k]);
				dispatch( setInvoices(invoices) );
			}			
		})
	}
}

export function moveDraftToFinished(){
	return (dispatch, getState) =>{
		let {finished, currentDraft, userData} = getState();
		//save to firebase, get the new id and put that in current draft before moving to state
		let newFinishedRef = firebase.database().ref('finished')
			.child(userData.uid)
			.push();

		let newFinishedId = newFinishedRef.key;
		currentDraft.id = newFinishedId;

		newFinishedRef.set(currentDraft)
			.then(()=>{
				finished.push(currentDraft);
				dispatch( setFinished( finished ));
				dispatch( setCurrentDraft({ size:null, material:null, detail:null }) );
			})
	}
}

export function moveFinishedToDraft(ind){
	return (dispatch, getState) => {
		let {finished} = getState();
		let currentDraft = finished[ind];
		currentDraft.size = null;
		
		dispatch( setCurrentDraft( currentDraft ) );

		dispatch( deleteFinished( ind ) );
	}
}

export function deleteFinished(ind){
	return (dispatch, getState) =>{
		let {finished, userData} = getState();
		let toBeDeleted = finished[ind];
		firebase.database().ref('finished')
			.child(userData.uid)
			.child(toBeDeleted.id)
			.set(null)
		.then(()=>{
			console.log('deleteAction successfully finished');
			dispatch( fetchMyFinished() );
		})

	}
}

export function clearFinished(){
	return (dispatch, getState) =>{
		let {userData} = getState();
		firebase.database().ref('finished')
			.child(userData.uid)
			.set(null)
		.then(()=>{
			dispatch( setFinished([]) );
		})

	}
}

export function fetchShippingAddress(){
	return (dispatch, getState)=>{
		let {userData} = getState();
		firebase.database().ref('users')
			.child(userData.uid)
			.child('shippingAddress')
			.once('value')
		.then((addSnap)=>{
			let addressValue = addSnap.val();
			dispatch( setAddress( addressValue ) );
		})
		
	}
}

export function updateShippingAddress(uid,address){
	return (dispatch, getState)=>{
	firebase.database().ref('users')
			.child(uid)
			.child('shippingAddress')
			.set(address)
		.then(()=>{
			dispatch( setAddress(address) )
		})
	}
}


//sets the state/ reducers
export function setUserData(user){

	return{
		type: types.UPDATE_USER_DATA,
		user
	}
}

export function setUserCard(usercard){

	return{
		type: types.UPDATE_USER_CARD,
		usercard
	}
}

export function setAvailableMaterials(materials){

	return{
		type: types.UPDATE_AVAILABLE_MATERIALS,
		materials
	}
}

export function setAvailableDetails(details){

	return{
		type: types.UPDATE_AVAILABLE_DETAILS,
		details
	}
}

export function setAvailableSizes(sizes){

	return{
		type: types.UPDATE_AVAILABLE_SIZES,
		sizes
	}
}

export function setCurrentDraft(draft){

	return{
		type: types.UPDATE_CURRENT_DRAFT,
		draft
	}
}

export function setFinished(finished){
	return {
		type: types.UPDATE_FINISHED,
		finished
	}
}

export function setInvoices(invoices){
	return {
		type: types.UPDATE_INVOICES,
		invoices
	}
}

export function setAddress(shippingAddress){
	return {
		type: types.UPDATE_SHIPPING_ADDRESS,
		shippingAddress
	}
}