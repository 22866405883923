import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Col } from 'react-bootstrap';
class DoneScreen extends Component{
	_startAgain(){
		this.props.changeSelector('material');
	}

	render(){
		return (
			<div className="container-fluid">
				<div className="row" style={{marginTop: 16}}>

					<Col sm={12}>
						<h3>Finished!</h3>
						<p>What would you like to do next?</p>
					</Col>
					
					<Col xs={12} sm={12} md={12} lg={6} xl={6}>
						<LinkContainer to="/checkout">
							<Button style={{marginTop: 8}} variant="success" block>Checkout <i className="material-icons" style={{verticalAlign:'middle'}}>shopping_cart</i></Button>
						</LinkContainer>
					</Col>

					<Col xs={12} sm={12} md={12} lg={6} xl={6}>
						<Button variant="secondary" style={{marginTop: 8}} onClick={this._startAgain.bind(this)} block>Make Another</Button>
					</Col>

				</div>
			</div>
		);
	}
}

export default DoneScreen;