import React, {Component} from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../actions';

import { injectStripe } from 'react-stripe-elements';

// import { LinkContainer } from 'react-router-bootstrap';

import createInvoice from '../../lib/createInvoice';

import CheckoutAccount from './account';
import CheckoutForm from './payment';
import CheckoutInvoice from './invoice';

import ThankYou from './thankyou';

import { Container, Row, Col, Modal, Form } from 'react-bootstrap';

import SelectUSState from 'react-select-us-states';

//const PLANKET_PRICE = 19.95;

class UserShippingAddress extends Component{
	render(){
		let {name, address1, address2, city, state, zip} = this.props;
		return (
			<p>
			{name && (<span><small>{name}</small></span>)}
			<br />
			{address1 && (<span><small>{address1}</small></span>)}
			{address2 && (<span><small>{address2}</small></span>)} 
			<br />
			{city && (<span><small>{city + ', ' + state + ' ' + zip}</small></span>)}
			<br />
			</p>
		);
	}
}

class CheckoutShipping extends Component{
	state = {
		address1: '',
		address1Error: null,
		address2: '',
		city: '',
		cityError: null,
		state: '',
		stateError: null,
		zip: '',
		zipError: null
	};

	render(){
		console.log('rendering shippindaddress area: ', this.props);
		return (
			<Row>
				<Container>
				<h6>Shipping Address</h6>

				{!this.props.shippingAddress && (
					<Form>
					<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
				  	<div className="form-group bmd-form-group bmd-form-group-sm">
				    	<Form.Control type="text" placeholder="Name" required isInvalid={(this.props.shippingError === 'name')} onChange={(v)=>this.props.updateShippingAddress({name:v})} />
				  	</div>
				  </Col>

					<Col xs={12} sm={12} md={12} lg={12}>
				  	<div className="form-group bmd-form-group bmd-form-group-sm">
				    	<Form.Control type="text" placeholder="Email" required isInvalid={(this.props.shippingError === 'email')}  id="email" onChange={(v)=>this.props.updateShippingAddress({email: v})} />
				  	</div>
				  </Col>

				  <Col xs={12} sm={12} md={12} lg={12}>
					  <div className="form-group bmd-form-group bmd-form-group-sm">
					    <Form.Control type="text" placeholder="Address" required isInvalid={(this.props.shippingError === 'address1')}  id="address1"  onChange={(v)=>this.props.updateShippingAddress({address1:v})}/>
					  </div>
				  </Col>
				  
				  <Col xs={12} sm={12} md={12} lg={12}>
				  	<div className="form-group bmd-form-group bmd-form-group-sm">
				    	<Form.Control type="text" required id="address2" isInvalid={(this.props.shippingError === 'address2')}  placeholder="Address 2 (ie. Apt, Suite, etc)"  onChange={(v)=>this.props.updateShippingAddress({address2:v})} />
				  	</div>
				  </Col>

				  <Col xs={12} sm={12} md={12} lg={12}>
				  	<div className="form-group bmd-form-group bmd-form-group-sm">
				    <Form.Control type="text" placeholder="City" required  isInvalid={(this.props.shippingError === 'city')}  onChange={(v)=>this.props.updateShippingAddress({city:v})}/>
				    </div>
				  </Col>

				  <Col xs={6} sm={6} md={6} lg={6}>
					  <div className="form-group bmd-form-group bmd-form-group-sm">
					    <SelectUSState className={"form-control " + ( (this.props.shippingError === 'state') ? 'is-invalid' : '') } required id="state"  isInvalid={(this.props.shippingError === 'state')}  onChange={(v)=>this.props.updateShippingAddress({state:v})} />
					  </div>
					</Col>
					<Col xs={6} sm={6} md={6} lg={6}>
					  <div className="form-group bmd-form-group bmd-form-group-sm">
					    <Form.Control type="text" required isInvalid={(this.props.shippingError === 'zip')} id="zip" placeholder="Zip"  onChange={(v)=>this.props.updateShippingAddress({zip:v})} />
					  </div>
				
					</Col>
					</Row>
					</Form>
				)}

				{this.props.shippingAddress && (
					<UserShippingAddress {...this.props.shippingAddress} />
				)}
				</Container>
			</Row>
		);
	}
}

class Checkout extends Component{
	state = {
		processing: false,
		cardValid: false,
		cardError: false,
		expiryError: false,
		cvcError: false,
		invoiceId: null,
		done: false,
		tempValidating: true,
		showAccountModal: false,
		shippingAddress: {},
		shippingError: false
	}

	_deleteItem(ind){
		if(ind>=0 && ('number' === typeof ind ) ){
			// console.log('attempting to delete', ind);
			this.props.deleteFinished(ind);
		}
	}

	_editItem(ind){
		if(ind>=0 && ('number' === typeof ind ) ){
			this.props.moveFinishedToDraft(ind);
		}
	}

	_cardChanged(c){
		//console.log(c)
		if(c.complete && !c.error){
			this.setState({cardValid: true});
		}else{
			this.setState({cardValid: false});
		}
	}

	_expiryChanged(c){

		//console.log(c);
		if(!c.empty && !c.complete){
			this.setState({expiryError: true})
		}else{
			this.setState({expiryError: false})
		}
	}

	_cvcChanged(c){

		//console.log(c);
		if(!c.empty && !c.complete){
			this.setState({cvcError: true})
		}else{
			this.setState({cvcError: false})
		}

	}

	async submit(ev) {
		this.setState({processing: true});
		let {finished,userData,shippingAddress} = this.props;
		let {invoiceId, invoice} = this.state;

		if(!shippingAddress || !shippingAddress.address1){
			// console.log('on submit, props did NOT have a valid shipping address');
			// console.log("using state value");
			 shippingAddress = this.state.shippingAddress;
		}
		// 1. create invoice node from our finished data and address
		// 2. pass the new invoice id to the endpoint gateway
		// 3. attempt to run payment
		// 4. if payment succeeds redirect to thank you page with invoice id
		// 5. if fails, delete that invoice node
	// console.log('stripeoutout',this.props.stripe);
	// 		try{
	// 		let {paymentMethod} = await this.props.stripe.createPaymentMethod('card', {billing_details: {name: userData.displayName,email:userData.email}})
	//   	console.log("elments cretaec card:", paymentMethod);
	//   }catch(e){
	//   	console.warn(e);
	//   }
	// return;
		try{
			let stripeName = shippingAddress.name;
	  	let {token} = await this.props.stripe.createToken({type:'card',name: stripeName});
	  	// console.log('got the token',token);
	  	if(!token){
	  		throw new Error('Could not get token')
	  	}
	  	
		  if(!invoiceId){
				invoice = createInvoice(finished, shippingAddress, userData.uid);
				invoiceId = invoice.invoiceId;

				this.setState({invoiceId: invoice.invoiceId, invoice: invoice});
			}

			// console.log('state before post', this.state);

		  let response = await fetch("https://us-central1-planket-3c72d.cloudfunctions.net/charge", {
		    method: "POST",
		    //headers: {"Content-Type": "application/json"},
		    body: JSON.stringify({token:token.id, userDataUid:userData.uid, invoiceId: invoiceId }),
				mode: "cors"
		  });

		  // console.log('all done', {response});

		  if (response.ok){
		  	let resBody = await response.json();
		  	// console.log('resbody',resBody);
		  	if(resBody.error){
		 			throw resBody.error;
		 		}else{

			  	// console.log("Purchase Complete!")
			  	this.setState({processing: false, done: true, successInvoiceId: invoiceId, invoice: invoice});
			  	this.props.clearFinished();
			  	this.props.fetchMyInvoices();
		  	}
		 	}else{
		 		// console.error(response);
		 		throw new Error('ERROR!');
		 	}


		}catch(e){
			// console.log('ERROR',e);
			this.setState({processing: false});
		}
	}
	
	validate(){
		let {shippingAddress} = this.props;

		let {cardValid,expiryError,cvcError} = this.state;
		if(!cardValid){
			// console.log('invalid card');
			return false;
		}

		if(expiryError){
			// console.log('invalid expiry');
			return false;
		}

		if(cvcError){
			// console.log('invalid cvc');
			return false;
		}

		if(!cardValid){
			// console.log('NO CARD');
			this.setState({cardError: true})
			return false;
		}

		this.setState({expiryError: false, cvcError: false})

		if(!shippingAddress || !shippingAddress.address1){
			console.log('in validate, props did NOT have a valid shipping address');
			console.log("using state value");
			 shippingAddress = this.state.shippingAddress;
		}

		if(shippingAddress){
			console.log('validating shippingAddress:', shippingAddress);

			let errs = ['name','email','address1','city','state','zip'].map((f)=>{
				if(!shippingAddress[f] || shippingAddress[f].length < 2){
					return f;
				}else{
					return null;
				}
			}).filter(a => a);

			if(errs.length > 0 ){
				console.log('Error with shipping address:',errs[0]);
				this.setState({shippingError: errs[0] });
				return false;	
			}else{
				console.log('card and address are VALIDATED');
				this.submit();
			}
			
		}else{
			console.log('Error, missing shipping address');
			return false;
		}

	}

	componentDidMount(){
		if(this.props.userData.isAnonymous){
			this.setState({showAccountModal: true});
		}
	}


	_updateShippingAddress(v){
		console.log('changed!', v);

		let oks = Object.keys(v);
		let nval = null;
		if(oks[0] === 'state'){
			console.log( v[oks[0]]);
			nval = v.state;
		}else{
			let f = v[oks[0]].currentTarget
			if( f.checkValidity() === false){
				console.log('ERROR WITH' + oks[0]);
				this.setState({shippingError: oks[0]})
			}
			nval = v[oks[0]].currentTarget.value;
		}

		this.setState((state,props)=>{
			let {shippingAddress} = state;
			shippingAddress[oks[0]] = nval;
			return {shippingAddress: shippingAddress}
		},()=>{
			console.log('updated shipping address state:', this.state);
		})	
	}

	render(){
		let orderTotal = '$' + ( (this.props.finished.length * 19.95).toFixed(2));
		return (
			<div className="container-fluid">

				{!this.state.done && (
					<Row>
						<Col>
							<h4>{ 'Complete your order'}</h4>
							<h5>{ 'Total: ' + orderTotal}</h5>
						</Col>
					</Row>
				)}

				{!this.state.done && (
				<div className="row">

					<Col xs={12} sm={5} lg={6} xl={6} md={5}>
					{this.props.userData.loggedIn && (
						<CheckoutShipping {...this.props} shippingError={this.state.shippingError} updateShippingAddress={this._updateShippingAddress.bind(this)} />
					)}
					</Col>

					<Col xs={12} sm={7} lg={6} xl={6} md={7}>
					{this.props.userData.loggedIn && (
						<CheckoutForm 
							cardError={this.state.cardError}
							expiryChanged={this._expiryChanged.bind(this)} 
							cvcChanged={this._cvcChanged.bind(this)} 
							cardChanged={this._cardChanged.bind(this)} 
							submitPayment={this.validate.bind(this)} 
							paymentButtonActive={(!this.state.cardValid )}
							processingPayment={this.state.processing} />
					)}
					</Col>

					<Col xs={12} sm={12} lg={12} md={12}>
						<CheckoutInvoice availableMaterials={this.props.availableMaterials} availableDetails={this.props.availableDetails} checkoutItems={this.props.finished} editItem={this._editItem.bind(this)} deleteItem={this._deleteItem.bind(this)} />
					</Col>

				</div>
				)}

				{this.state.done && (
					<ThankYou {...this.state.invoice} />
				)}

				{this.props.userData.isAnonymous && (
					<Modal show={this.state.showAccountModal} onHide={()=>{ 
						console.log('dismissed modal!');
						this.setState({showAccountModal:false})
					}}>
						<Modal.Header>
							<h4>Create a Planket.shop Account?</h4>
						</Modal.Header>
						<Modal.Body>
							<CheckoutAccount dismissModal={()=> this.setState({showAccountModal:false}) } />
						</Modal.Body>
						<Modal.Footer>
							<button type="button" onClick={()=> this.setState({showAccountModal:false})} className="btn btn-info">No thanks</button>
						</Modal.Footer>
					</Modal>
				)}

			</div>
		);
	}
}

function mapStateToProps(state){
  return {
   userData: state.userData,
   currentDraft: state.currentDraft,
   finished: state.finished,
   shippingAddress: state.shippingAddress,
   availableMaterials: state.availableMaterials,
   availableDetails: state.availableDetails
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default injectStripe( connect(mapStateToProps,mapDispatchToProps)(Checkout) );