//our type signals
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const UPDATE_USER_CARD = 'UPDATE_USER_CARD';


//for teh create action types
export const UPDATE_AVAILABLE_MATERIALS = 'UPDATE_AVAILABLE_MATERIALS';

export const UPDATE_AVAILABLE_DETAILS = 'UPDATE_AVAILABLE_DETAILS';

export const UPDATE_AVAILABLE_SIZES = 'UPDATE_AVAILABLE_SIZES';

//draft
export const UPDATE_CURRENT_DRAFT = 'UPDATE_CURRENT_DRAFT';

export const MOVE_DRAFT = 'MOVE_DRAFT';

export const UPDATE_FINISHED = 'UPDATE_FINISHED';

export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS';

export const UPDATE_INVOICES = 'UPDATE_INVOICES';