import React, { Component } from 'react';

import Checkout from '../components/checkout';

class CheckoutContainer extends Component{

	render(){
		return <Checkout />
	}
}

export default CheckoutContainer;