import React, { Component } from 'react';

import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe} from 'react-stripe-elements';
import { Container, Spinner, Row, Col, Form} from 'react-bootstrap';


class CheckoutPayment extends Component{
	render(){
		return (
				<div>
					<Form validated={!this.props.cardError}>
					<Row>
						<Col xs={12} sm={12} lg={12} md={12} style={{marginBottom:'8px'}}>
							<h5>Credit Card</h5>
						</Col>
					</Row>

					<Row>
						<Col xs={12} sm={12} lg={12} md={12} style={{marginBottom:'8px'}}>
							<CardNumberElement className="form-control form-control-lg" onChange={this.props.cardChanged} />
						</Col>
					</Row>

					<Row>
					<Col xs={4} sm={4} lg={4} xl={4} md={4} style={{marginBottom:'8px'}}>
							<CardExpiryElement className="form-control form-control-lg" onChange={this.props.expiryChanged} />
					</Col>
					
					<Col xs={{span:3,offset:2}} sm={3} lg={{span:3,offset:3}} xl={3} md={3} style={{marginBottom:'8px'}}>
						<CardCVCElement className="form-control form-control-lg" onChange={this.props.cvcChanged} />
					</Col>
					</Row>

					<Container>
					<Row>
					<Col xs={12} sm={12} lg={12} md={12} style={{marginBottom:'8px',backgroundColor: '#f8f8f8'}}>
						<button type="button" 
							style={{marginTop: '8px', marginBottom: '8px', width: '100%'}}
							disabled={(this.props.paymentButtonActive) ? 'disabled': ''} 
							className={ ((this.props.paymentButtonActive) ? 'btn btn-raised' : 'btn btn-raised btn-success')} 
							onClick={this.props.submitPayment}>Purchase
							{this.props.processingPayment && (
							<Spinner animation="border" role="status" size="sm" />
							)}
						</button>

					</Col>
					</Row>
					</Container>
				</Form>
			</div>
		);
	}
}
const CheckoutForm = injectStripe(CheckoutPayment);

export default CheckoutForm;