import createReducer from '../lib/createReducer';
import * as types from '../actions/types';

//the users data/auth
export const userData = createReducer({},{
	[types.UPDATE_USER_DATA](state,action){
		var updatedUserData = action.user;
		return updatedUserData;
	}
});

export const userCardData = createReducer({},{
	[types.UPDATE_USER_CARD](state,action){
		var updatedUserCardData = action.usercard;
		return updatedUserCardData;
	}
});


// create state vars

export const availableMaterials = createReducer([],{
	[types.UPDATE_AVAILABLE_MATERIALS](state,action){
		var updatedMaterials = action.materials;
		return updatedMaterials;
	}
});

export const availableDetails = createReducer([
		{name: 'D01', image: require('../components/selector/images/black_white_square_logo.png'), id: 'D01'},
	],{
	[types.UPDATE_AVAILABLE_DETAILS](state,action){
		var updatedDetails = action.details;
		return updatedDetails;
	}
});

export const availableSizes = createReducer([
		{name: 'standard', image: require('../components/selector/images/black_white_square_logo.png'), id: 'ST01'},
	],{
	[types.UPDATE_AVAILABLE_SIZES](state,action){
		var updatedSizes = action.sizes;
		return updatedSizes;
	}
});

//current draft
export const currentDraft = createReducer({material: null, detail: null, size: null},{
	[types.UPDATE_CURRENT_DRAFT](state,action){
		let{ material, detail, size} = action.draft;
		return {material, detail, size};
	}
});

//saved/finished plankets
export const finished = createReducer([],{
	[types.UPDATE_FINISHED](state,action){
		let updateFinished = action.finished;
		return updateFinished;
	}
});

export const invoices = createReducer([],{
	[types.UPDATE_INVOICES](state,action){
		let updateInvoices = action.invoices;
		return updateInvoices;
	}
});

//shipping address
export const shippingAddress = createReducer({name:'',email:'',address1:'',address2:'',city:'',state:'',zip:''},{
	[types.UPDATE_SHIPPING_ADDRESS](state,action){
		let updateAddress = action.shippingAddress;
		return updateAddress;
	}
});