import React, {Component} from 'react';

import { Col } from 'react-bootstrap';

import firebase from 'firebase/app';
import 'firebase/auth';

class CheckoutSignup extends Component{
	state = {
		email: '',
		emailError: null,
		password: '',
		passwordError: null,
		passwordConfirm: '',
		passwordConfirmError: null
	}
	_signup(){
		let {email, password, passwordConfirm} = this.state;

		if(email.indexOf('@') < 0){
			this.setState({emailError: 'Please enter a valid email'});
			return;
		}else{
			this.setState({emailError: null})
		}

		if(email.length < 4){
			this.setState({emailError: 'Please enter a valid email'});
			return;
		}else{
			this.setState({emailError: null})
		}

		if(password.length < 6){
			this.setState({passwordError: 'Password must be atleast 6 characters'});
			return;
		}else{
			this.setState({passwordError: null})
		}

		if(password !== passwordConfirm){
			this.setState({passwordConfirmError: 'Password and Confirmation Password must match!'});
			return;
		}else{
			this.setState({passwordConfirmError: null})
		}

		this._createUser();
	}

	_createUser(){
		let {email, password} = this.state;

		firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
		  // Handle Errors here.
		  if(error){
  			let {code, message} = error;
  			// console.log('problem signing up: ')
  			// console.log(code);
  			// console.log(message);

  		}else{
  			// console.log('attempting to link from anon account');
	  		var credential = firebase.auth.EmailAuthProvider.credential(email, password);
	  		firebase.auth().currentUser.linkAndRetrieveDataWithCredential(credential).then(function(usercred) {
			  	var user = usercred.user;
			  	// console.log("Anonymous account successfully upgraded", user);
				}, function(error) {
			  	// console.log("Error upgrading anonymous account", error);
				});
			}

		});

	}
	
	render(){
		return (
			<div className="row">
				<div className="col-sm-12">
					<h5>Signup </h5>
					<form>
					  <div className="form-group">
					    <label htmlFor="exampleInputEmail1" className="bmd-label-floating">Email address</label>
				    	<input type="email" className={'form-control ' + ((this.state.emailError) ? 'is-invalid': '')} id="exampleInputEmail1" onChange={(txt)=>{this.setState({email:txt.target.value})}} />
					  </div>
					  <div className="form-group">
					    <label htmlFor="exampleInputPassword1" className="bmd-label-floating">Password</label>
					    <input type="password" className={'form-control ' + ((this.state.passwordError) ? 'is-invalid': '')}  id="exampleInputPassword1" onChange={(txt)=>{this.setState({password:txt.target.value})}} />
					  </div>
					  <div className="form-group">
					    <label htmlFor="exampleInputPassword2" className="bmd-label-floating">Confirm Password</label>
					    <input type="password"  className={'form-control ' + ((this.state.passwordConfirmError) ? 'is-invalid': '')}  id="exampleInputPassword2" onChange={(txt)=>{this.setState({passwordConfirm:txt.target.value})}} />
					  </div>
					</form>
					<button type="button" className="btn btn-primary btn-raised" onClick={this._signup.bind(this)}>Create Account</button>
				</div>
				<div className="col-sm-8" style={{marginTop:'24px'}}>
					<button type="button" onClick={this.props.showLogin.bind(this)} className="btn btn-secondary">Already have an account? Login Here</button>
				</div>
			</div>
		);
	}
}

class CheckoutLogin extends Component{
	state = {
		email: '',
		password: '',
		emailError: null,
		passwordError: null,
	};

	_login(){
		let {email, password} = this.state;
		if((email.length < 4) || email.indexOf('@')<0 ){
			this.setState({emailError: true})
			return false;
		}else{
			this.setState({emailError: null})
		}

		if(password.length < 4){
			this.setState({passwordError: true})
			return false;
		}else{
			this.setState({passwordError: null})
		}

		firebase.auth().signInWithEmailAndPassword(email,password);
	}

	render(){
		return (
			<div className="row">
				<div className="col-sm-12">
				<h5>Login </h5>
					<form>
					  <div className="form-group">
					    <label htmlFor="exampleInputEmail1" className="bmd-label-floating">Email address</label>
					    <input type="email" className={'form-control ' + ((this.state.emailError) ? 'is-invalid': '')} id="exampleInputEmail1" onChange={(txt)=>{this.setState({email:txt.target.value})}} />
					  </div>
					  <div className="form-group">
					    <label htmlFor="exampleInputPassword1" className="bmd-label-floating">Password</label>
					    <input type="password" className={'form-control ' + ((this.state.passwordError) ? 'is-invalid': '')}id="exampleInputPassword1" onChange={(txt)=>{this.setState({password:txt.target.value})}} />
					  </div>
					</form>
					<button type="button" className="btn btn-primary btn-raised" onClick={this._login.bind(this)}>Login</button>
				</div>
				<div className="col-sm-12" style={{marginTop:'24px'}}>
					<button type="button" onClick={this.props.showSignup.bind(this)} className="btn btn-secondary">Don't have an account? Create a Planket.shop Account</button>
				</div>
			</div>
		);
	}
}

class CheckoutAccount extends Component{
	state = {
		accountStep: 'default'
	}

	_showLogin(){
		this.setState({accountStep: 'login'});
	}
	_showSignup(){
		this.setState({accountStep: 'signup'});
	}
	_skipAccount(){
		this.setState({accountStep: 'skip'});
		this.props.dismissModal();
	}

	render(){
		if(this.state.accountStep === 'default'){
			return (
				<div className="row">
					<div className="col-sm-12">
						<button type="button" onClick={this._showSignup.bind(this)} className="btn btn-raised btn-success">Yes, Create an Account</button>
					</div>
					<Col xs={12} sm={12} md={12} lg={12} style={{marginTop:'24px'}}>
						<span>If you already have an account, you can login here </span>
						<button type="button" onClick={this._showLogin.bind(this)} className="btn btn-light">Login with your email</button>
					</Col>
				</div>
			);
		}

		if(this.state.accountStep === 'login'){
			return (
				<CheckoutLogin showSignup={this._showSignup.bind(this)} skipAccount={this._skipAccount.bind(this)} />
			);
		}

		if(this.state.accountStep === 'signup'){
			return (
				<CheckoutSignup  showLogin={this._showLogin.bind(this)} skipAccount={this._skipAccount.bind(this)} />
			);
		}	

		if(this.state.accountStep === 'skip'){
			return null;
		}	

	}
}

export default CheckoutAccount;