import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
class Footer extends React.Component{
  render(){
    return (
      <Container style={{marginTop: 16}}>
        <Row>
          <Col xs={12}>
          <small><a href="/about">About</a></small>&sdot;
          <small><a href="/contact">Contact</a></small>&sdot;
          <small><a href="/terms">Terms of Use</a></small>&sdot;
          <small><a href="/privacy">Privacy Policy</a></small>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="text-muted small mb-4 mb-lg-0">&copy; Planket.shop 2020. All Rights Reserved.</p>
            <a href="https://facebook.com/planket.shop" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook fa-2x fa-fw"></i>
            </a>
            <a href="https://twitter.com/planketshop" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter-square fa-2x fa-fw"></i>
            </a>
            <a href="https://instagram.com/planket.shop" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram fa-2x fa-fw"></i>
            </a>
          </Col>
        </Row>
      </Container>
    )
  }
}
export default Footer;