import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../actions';

import { Row } from 'react-bootstrap';
import moment from 'moment';

class OrderRow extends Component{
	render(){
		return (
			<tr>
				<td><span style={{fontSize:'.8em'}}>{ moment(this.props.date).format("YYYY-MM-DD\n  hh:mm:s") }</span></td>
				<td><span style={{fontSize:'.8em'}}>{this.props.items.length}</span></td>
				<td><span style={{fontSize:'.8em'}}>{'$' + (Math.round(this.props.total * 1000)/1000).toFixed(2)}</span></td>
				<td><span style={{fontSize:'.8em'}}>{(this.props.status && (this.props.status!=='')) ? this.props.status : 'Pending'}</span></td>
			</tr>
		);
	}
}

class Orders extends Component{
	render(){
		return (
			<Row>
			<div className="col-12">
				<hr className="hr-dark" />
				<h6>Your Orders</h6>
				{this.props.invoices && (this.props.invoices.length > 0) && (
					<table className="table table-striped">
						<thead>
							<tr>
								<th>Date</th>
								<th>Qty</th>
								<th>Total</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
						{this.props.invoices.sort((a,b)=>{ return moment(b.date).valueOf() - moment(a.date).valueOf() }).map((i,ind)=>{
							return <OrderRow {...i} key={ind} />
						})}
						</tbody>
					</table>
				)}
			</div>
			</Row>
		);
	}
}


function mapStateToProps(state){
  return {
   userData: state.userData,
   invoices: state.invoices
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Orders);