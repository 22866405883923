import React, { Component } from 'react';

import StepContainer from './stepcontainer';
import SelectContainer from './selectcontainer';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../actions';

class CreateContainer extends Component{
  state = {
    activeSelect: 'material'
  };

  _changeSelector(sel){
  	console.log('createcontainer: changing selector to:', sel);
  	this.setState({activeSelect: sel});
  }

	render(){
		return (
			<div className="container-fluid">
				
				<div className="row">

				  <div className="col-12">
				    <StepContainer activeSelect={this.state.activeSelect} changeSelector={this._changeSelector.bind(this)} />
				  </div>

				  <div className="col-12">
				    <SelectContainer activeSelect={this.state.activeSelect} changeSelector={this._changeSelector.bind(this)}  />
				  </div>



				</div>
			</div>
		)
	}
}


function mapStateToProps(state){
  return {
   userData: state.userData,
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateContainer);