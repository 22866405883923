import React, {Component} from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../actions';

import { LinkContainer } from 'react-router-bootstrap';

import {Container, Row, Col, Button} from 'react-bootstrap';

class ThankYou extends Component{
	render(){
		let citystatezip = (this.props.city + ', ' + this.props.state + ' ' + this.props.zip);
		return (
			<Container>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
						<h5>Thank you</h5>
						<p>You will receive an order confirmation via email shortly.</p>
					</Col>

					<Col xs={6} sm={6} md={6} lg={6}>

						<div><small>{this.props.name}</small></div>
						<div><small>{this.props.address1}</small></div>
						<div><small>{this.props.address2}</small></div>
						<div>
							<small>{citystatezip}</small>
						</div>
					</Col>

					<Col xs={6} sm={6} md={6} lg={6}>
						<div>
							{this.props.items.map((i,ind)=>{
								return (
								<div key={ind}>
									{i.material}
									{i.detail}
									{i.size}
								</div>
								)
							})}
						</div>

						<div>
							<strong>{'Total: $' + this.props.total}</strong>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={6} sm={6} md={6} lg={6}>
						<LinkContainer to="/create">
							<Button size="sm">Create a new Planket</Button>
						</LinkContainer>
					</Col>

					<Col xs={6} sm={6} md={6} lg={6}>
						{this.props.userData && !this.props.userData.isAnonymous && (
						<LinkContainer to="/account">
							<Button size="sm" variant="secondary">View Your Orders</Button>
						</LinkContainer>
						)}

						{this.props.userData && this.props.userData.isAnonymous && (
						<LinkContainer to="/account">
							<Button size="sm" variant="secondary">Create an Account</Button>
						</LinkContainer>
						)}

					</Col>
				</Row>
			</Container>
		);
	}
}

function mapStateToProps(state){
  return {
   userData: state.userData,
   currentDraft: state.currentDraft,
   finished: state.finished,
   shippingAddress: state.shippingAddress
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ThankYou);