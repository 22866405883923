import firebase from 'firebase/app';
import 'firebase/database';

import moment from 'moment';

export default function createInvoice(finishedItems, shippingAddress, userId, chargeId = ''){
	let {name,address1,address2,city,state,zip, email} = shippingAddress;

	let invoice = {
		date: moment().format('YYYY-MM-DD HH:mm:ss'),
		time: moment().valueOf(),
		items: finishedItems,
		name: name || '',
		email: email || '',
		address1: address1 || '',
		address2: address2 || '',
		city: city || '',
		state: state || '',
		zip: zip || '',
		total: 0,
		tax: 0,
		shipping:0,
		coupon: '',
		userId: userId || '',
		chargeId: chargeId || '',
		invoiceId: '',
		status: 'open'
	};
	
	let items = finishedItems.filter((i) => {
		return (i);
	});

	let total = Math.round( (items.length * 19.95) * 100 ) / 100;

	invoice.items = items;
	invoice.total = total;

	let invoiceKeyRef = firebase.database().ref('invoices')
		.child(userId)
		.push();
	
	invoice.invoiceId = invoiceKeyRef.key;
	invoiceKeyRef.set(invoice);

	return invoice;
}