import React, { Component } from 'react';

import { Row, Col, Image } from 'react-bootstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../actions';

let checkStyle = {color: 'green', fontWeight: '600',position: 'absolute', left: '70%', top:'10%', backgroundColor:'white', borderRadius: 20};

class SizeSelector extends Component{
	componentWillMount(){
		this.props.fetchAvaiableSizes();
	}

	_selectSize(val){
		this.props.updateDraftSize(val);
	}

	_addToCart(){
		this.props.updateDraftSize('ST01');

		this.props.moveDraftToFinished();
		this.props.changeSelector('done');
	}

	render(){
		let materialImage = this.props.availableMaterials.filter((am)=>{
			return (am.id=== this.props.currentDraft.material);
		});
		let detailImage =  this.props.availableDetails.filter((ad)=>{
			return (ad.id=== this.props.currentDraft.detail);
		});
		return (
			<div className="container-fluid">
				<Row>
					<div className="col-sm">
						<h5>Finalize your Planket</h5>
						<p className="text-success">If you are satisfied with your blanket and pillow design, you can finish &amp; pay. </p>
						<p>If you want to make changes to your Planket, use the menu above to select a different blanket or pillow design</p>
					</div>
				</Row>

				<Row>
					{this.props.availableSizes.map((m)=>{
						return (
							<Col xs={12} sm={12} md={7} lg={6} className="col-sm-4" key={m.id}
								onClick={() => this._selectSize(m.id) }>

								{(this.props.currentDraft.size === m.id) && (
									<i className="material-icons" style={checkStyle}>check</i>
								)}
			      		
			      		<Image src={materialImage[0].image} thumbnail fluid />

			      		<Image src={detailImage[0].image} style={{border: '2px solid #fff', position: 'absolute', height: '70%', left: '18%', top:'10%'}}/>

			    		</Col>
			    	);
					})}

					{ this.props.currentDraft.detail && this.props.currentDraft.material && (
						<Col xs={12} sm={12} md={5} lg={6}>
						  <button onClick={this._addToCart.bind(this)} style={{width: "100%"}} type="button" className="btn btn-raised btn-success btn-lg">ADD TO CART</button>
						</Col>
					)}
			  </Row>

			</div>
		);
	}
}

function mapStateToProps(state){
  return {
  	availableSizes: state.availableSizes,
  	availableMaterials: state.availableMaterials,
  	availableDetails: state.availableDetails,
   	currentDraft: state.currentDraft
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(SizeSelector);