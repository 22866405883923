import React, { Component } from 'react';
import {  BrowserRouter as Router, Route, Switch } from "react-router-dom";

import './App.css';

import CreateContainer from './createcontainer';
import CheckoutContainer from './checkoutcontainer';
import AccountContainer from './accountcontainer';

import Menu from '../components/menu';
import Footer from '../components/footer';

import WelcomePage from '../components/welcome';

import firebase from 'firebase/app';
import 'firebase/auth';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../actions';

import {Elements} from 'react-stripe-elements';

class AppContainer extends Component {
  state = {
    email: null,
    loggedIn: false,
    displayName: null,
    uid: null
  }

  setUserInfo({email, uid, displayName, isAnonymous }){
  	let loggedIn = true;
    this.setState({uid:uid, email:email, displayName: displayName, loggedIn: true, isAnonymous })
    this.props.updateUserData({email,uid,displayName,loggedIn,isAnonymous});
  }

  unsetUserInfo(){
    console.log("user is NOT signed in");
    this.setState({uid:null, email:null, displayName: null, loggedIn: false })
		this.props.updateUserData({uid:null, email:null, displayName: null, loggedIn: false });
  }

  componentWillMount(){
    let c = this;

    c.props.fetchAvaiableMaterials();
    c.props.fetchAvaiableDetails();

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        c.setUserInfo(user);
        c.props.fetchMyFinished();
        c.props.fetchShippingAddress();
        c.props.fetchMyInvoices();
        // ...
      } else {
        firebase.auth().signInAnonymously();
      }

    }).bind(this)
  }

  render() {
    return (
		    	<Router>
		      	<div className="App">
              <Menu />
             <Switch>
		          <Route 
		            path="/create" 
		            render={()=>{
		              return (<CreateContainer />);
		            }}
		          />

		          <Route 
		            path="/checkout" 
		            render={()=>{
		              return (
		              	<Elements>
		              		<CheckoutContainer />
		              	</Elements>
		              );
		            }}
		          />

              <Route 
                path="/account" 
                render={()=>{
                  return (<AccountContainer />);
                }}
              />

              <Route 
                path="/"
                render={()=>{
                  return (<WelcomePage />);
                }}
              />
              </Switch>

              <Footer />

		      	</div>
		      </Router>
    );
  }
}

function mapStateToProps(state){
  return {
   userData: state.userData,
   currentDraft: state.currentDraft,
   finished: state.finished
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(AppContainer);
