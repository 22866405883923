import React, { Component } from 'react';

import {Button, Row, Col, Breadcrumb, Collapse } from 'react-bootstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../actions';

const menuCheckStyle={
	fontSize:'1.5em',
	backgroundColor: '#28a745',
	borderRadius: '.75em',
	color: 'white',
	opacity: .2
};

class StepList extends Component{
	state = {
		showBlanketTool: false,
		showDetailTool: false,
		showReviewTool: false,
	}

	_addToCart(){
		if(this.props.currentDraft.material && this.props.currentDraft.detail){
			this.props.moveDraftToFinished();
			this.props.changeSelector('done');
		}
	}

	_advanceProgress(){
		switch(this.props.activeSelect){
			default:
			case 'material':
				this.props.changeSelector('detail')
			break;

			case 'detail':
				this.props.changeSelector('size')
			break;

		}


	}

	render(){
		let {activeSelect, currentDraft} = this.props;
		let buttonDisabled = true;
		let buttonMessage = "Select a Blanket Material";
		let hideButton = true;
		let buttonVariant = "link";
		if(activeSelect === 'material'){
			if(currentDraft.material && !currentDraft.detail){
				buttonDisabled = false;
				buttonMessage = "Next: Pillow Design";
				buttonVariant = "success";
				// console.log('should show the detail');
			}else if(currentDraft.material && currentDraft.detail){
				buttonDisabled = false;
				buttonMessage = "Next: Pillow Design";
				buttonVariant = "success";
			}

		}

		if(activeSelect === 'detail'){
			buttonMessage = "Choose a pillow design";
			if(currentDraft.material && currentDraft.detail && !currentDraft.size){
				buttonDisabled = false;
				buttonMessage = "Click here to finalize your planket";
				buttonVariant = "success";
			}
		}

		if(activeSelect === 'size'){
			if(currentDraft.material && currentDraft.detail && !currentDraft.size){
				hideButton = false;
			}
		}

		if( activeSelect !=='done' ){
			return (
				<Row className="align-items-center">
					<Col xs={12} sm={12} md={12} lg={12} style={{marginTop: '8px'}}>
						<Breadcrumb style={{verticalAlign: 'middle'}}>

							<Breadcrumb.Item 
								ref={(ref) =>{ this.blanket_target = ref; }}
								active={(this.props.activeSelect === 'material' ? true: false)}
				  			onClick={()=>{this.props.changeSelector('material')}}>
				  			<span style={{zIndex:10}}>BLANKET</span>
				  			{this.props.currentDraft.material && (<span style={{position:'absolute',zIndex:1}}><i style={menuCheckStyle} className="material-icons">check</i></span>)}

							</Breadcrumb.Item>


							<Breadcrumb.Item
								ref={ (ref) => {this.detail_target = ref; }}
								active={(this.props.activeSelect === 'detail' ? true: false)}
				  			onClick={()=>{this.props.changeSelector('detail')}}>
				  			<span>PILLOW</span>
				  			{this.props.currentDraft.detail && (<span style={{position:'absolute'}}><i style={menuCheckStyle} className="material-icons">check</i></span>)}

							</Breadcrumb.Item>


							<Breadcrumb.Item
								ref={ (ref) => { this.review_target = ref; }}
						  	active={this.state.showReviewTool}
						  	onClick={()=>{
						  		if(this.props.currentDraft.material && this.props.currentDraft.detail){
						  			this.props.changeSelector('size')
						  		}
						  	}}>

						  	<span className={ (this.props.currentDraft.material && this.props.currentDraft.detail) ? ' text-success ': ''}>REVIEW</span>
						  	{this.props.currentDraft.size && (<span style={{position:'absolute'}}><i style={menuCheckStyle} className="material-icons" size={'.8em'}>check</i></span>)}

							</Breadcrumb.Item>

						</Breadcrumb>						 
						</Col>
						<Col>
							<Collapse in={hideButton}>
							<Button 
								disabled={buttonDisabled}
								ref={ref=> this.nextButtonRef = ref} 
								size="sm"
								variant={buttonVariant} 
								onClick={this._advanceProgress.bind(this)}
								block
								>
								{ buttonMessage }
							</Button>
						</Collapse>
						</Col>
				</Row>
			)
		}else{
			return null;
		}

	}
}

function mapStateToProps(state){
  return {
   userData: state.userData,
   currentDraft: state.currentDraft,
   availableMaterials: state.availableMaterials,
   availableDetails: state.availableDetails,
   availableSizes: state.availableSizes,
   finished: state.finished
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(StepList);