import React, {Component} from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../actions';

import {Row, Col} from 'react-bootstrap';

let checkStyle = {color: 'green', fontWeight: '600',position: 'absolute', left: '70%', top:'10%', backgroundColor:'white', borderRadius: 20};

class DetailSelector extends Component{
	componentWillMount(){
		this.props.fetchAvaiableDetails();
	}

	_selectDetail(val){
		this.props.updateDraftDetail(val);
	}

	render(){
		return (
			<div className="container-fluid">
				<Row style={{marginTop: 16}}>
					{this.props.availableDetails.map((m)=>{
						let {imageThumb, image} = m;
						if(!imageThumb){
							imageThumb = image;
						}
						const imgStyleObj = {objectFit: "cover", height:"100%", width: "100%", maxHeight: 180};
						if (this.props.currentDraft.detail === m.id) {
							imgStyleObj.backgroundColor = "green";
						}
						return (
							<Col xs={12} sm={12} md={6} lg={4}
							  key={m.id}
								onClick={() => this._selectDetail(m.id) }>
								
								{(this.props.currentDraft.detail === m.id) && (
									<i className="material-icons" style={checkStyle}>check</i>
								)}

			      		<img
			      			src={imageThumb}
									style={imgStyleObj}
			      			className={(this.props.currentDraft.detail === m.id) ? "img img-thumbnail border border-success" : "img img-thumbnail"}
			      			alt={m.name} 
			      		/>
			    		</Col>
			    	);
					})}
			  </Row>

			</div>
		);
	}
}

function mapStateToProps(state){
  return {
  	availableDetails: state.availableDetails,
   	currentDraft: state.currentDraft
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(DetailSelector);