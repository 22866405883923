import React, { Component } from 'react';

/* state management */
import { Provider as ReduxProvider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducer from './reducers';

import firebase from 'firebase/app';
import 'firebase/auth';

import AppContainer from './containers/appcontainer';

import {StripeProvider} from 'react-stripe-elements';

// Initialize Firebase
const fbConfig = {
  apiKey: "AIzaSyC7x6UxWVxDmRot0UVgy388oHAv2jDgUVI",
  authDomain: "planket-3c72d.firebaseapp.com",
  databaseURL: "https://planket-3c72d.firebaseio.com",
  projectId: "planket-3c72d",
  storageBucket: "planket-3c72d.appspot.com",
  messagingSenderId: "1073858950353"
};
firebase.initializeApp(fbConfig);


class App extends Component {
  constructor(){
    super();
    this.state = {stripe: null};
  }
  componentDidMount(){
    if (window.Stripe) {
      this.setState({stripe: window.Stripe('pk_test_TlS51ep1DtTJobdm9ANx1XDn')});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe('pk_test_TlS51ep1DtTJobdm9ANx1XDn')});
      });
    }    
  }
  render() {
    return (
      <ReduxProvider store={store}>
        <StripeProvider stripe={this.state.stripe}>
            <AppContainer />
        </StripeProvider>
      </ReduxProvider>
    );
  }
}

const __DEV__ = false;

function configureStore( initialState ){
  
    const loggerMiddleware = createLogger({ predicate: (getState,action) => __DEV__ });

    const enhancer = compose(
      applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        ),
    );

  return createStore(reducer, initialState, enhancer);
}

//initial data for load
const store = configureStore({});

export default App;
