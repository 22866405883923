import React, { Component } from 'react';

import StepList from '../components/steps';

class StepContainer extends Component{
	render(){
		return (
			<StepList activeSelect={this.props.activeSelect} changeSelector={this.props.changeSelector} />
		);
	}
}

export default StepContainer;