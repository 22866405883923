import React, { Component } from 'react';

import Account from '../components/account';

class AccountContainer extends Component{
	render(){
		return (
			<div className="container-fluid">
				<Account />
			</div>
		);
	}
}

export default AccountContainer;