import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../actions';

import { LinkContainer } from 'react-router-bootstrap';
//import { NavLink } from 'react-router-dom';
import {Navbar, Nav, Image, Badge, Button} from 'react-bootstrap';

class Menu extends Component{
	navChange(eventKey){
		// console.log("CHANGED");
		// console.log(eventKey);
	}

	render(){
		return (
			<Navbar collapseOnSelect expand="md" variant="light" bg="light" sticky="top" style={{padding:'5px'}}>

	    			<Navbar.Brand style={{padding:'5px',height:'96px'}}>
			      	<Image
					        src={require('../img/logo_thumb.png')}
					        alt="Planket.shop logo"
					        height="90"
			      	/>
		    		</Navbar.Brand>
				

    		{this.props.finished.length > 0 && (
    		<Navbar.Toggle aria-controls="responsive-navbar-nav">
    			<span className="badge badge-light">{this.props.finished.length}</span>
    		</Navbar.Toggle>
    		) }

    		{this.props.finished.length < 1 && (
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
    		)}

    		<Navbar.Collapse id="responsive-navbar-nav">
    			<Nav className="mr-auto" onSelect={this.navChange}>
    				<LinkContainer to="/create" className="nav-link">
	    				<Nav.Link>
							    	<span className="btn btn-primary">Create A Planket&nbsp;
							    	<img src={require('../img/pillow_square_small.jpg')} height="24" alt="Planket.shop logo" />
							    	</span>
	    				</Nav.Link>
						</LinkContainer>

						<LinkContainer to="/account">
	    				<Nav.Link>
										{this.props.userData.isAnonymous && (	
												<Button variant="secondary">Sign Up</Button>
										)}
										
										{!this.props.userData.isAnonymous && (	
												<span className="btn btn-secondary">
													Account&nbsp;
													<i className="material-icons" style={{verticalAlign:'middle'}}>face</i>
												</span>
										)}
	    				</Nav.Link>
    				</LinkContainer>
    				
    				{ (this.props.finished.length > 0) && (
						<LinkContainer to="/checkout" >
	    				<Nav.Link>
						 		<Button variant="success">
						 			Your Cart&nbsp;
						 			<i className="material-icons" style={{verticalAlign:'middle'}}>shopping_cart</i>
						  		<Badge  style={{verticalAlign:'middle'}}>{this.props.finished.length}</Badge>
								</Button>
	    				</Nav.Link>
    				</LinkContainer>
						)}
					</Nav>
				</Navbar.Collapse>
  		</Navbar>
		);		
	}
}

function mapStateToProps(state){
  return {
   userData: state.userData,
   currentDraft: state.currentDraft,
   finished: state.finished
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Menu);