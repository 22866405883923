import React, {Component} from 'react';
import {Col, Image} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../actions';

let checkStyle = {color: 'green', fontWeight: '600',position: 'absolute', left: '70%', top:'10%', backgroundColor:'white', borderRadius: 20};
class MaterialSelector extends Component{
	componentWillMount(){
		this.props.fetchAvaiableMaterials();
	}

	_selectMaterial(val){
		this.props.updateDraftMaterial(val);
	}

	render(){
		return (
			<div className="container-fluid">
				<div className="row" style={{marginTop: 16}}>
					{this.props.availableMaterials.map((m)=>{
						let {imageThumb, image} = m;
						if(!imageThumb){
							imageThumb = image;
						}
						const imgStyleObj = {objectFit: "cover", height:"100%", width: "100%", maxHeight: 160};
						if (this.props.currentDraft.material === m.id) {
							imgStyleObj.backgroundColor = "green";
						}
						return (
							<Col xs={12} sm={12} md={6} lg={4}
								key={m.id} 
								onClick={() => this._selectMaterial(m.id) }>
								
								{(this.props.currentDraft.material === m.id) && (
									<i className="material-icons" style={checkStyle}>check</i>
								)}

			      		<Image
			      			src={imageThumb}
			      			style={imgStyleObj}
			      			className={(this.props.currentDraft.material === m.id) ? "img border border-success" : "img"}
			      			alt={m.name} 
			      			thumbnail
			      			fluid
			      			/>
			    		</Col>
			    	);
					})}
			  </div>
			</div>
		);
	}
}

function mapStateToProps(state){
  return {
  	availableMaterials: state.availableMaterials,
   	currentDraft: state.currentDraft
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(MaterialSelector);