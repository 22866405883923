import React, { Component } from 'react';

import Selector from '../components/selector';

class SelectContainer extends Component{
	render(){
		return (<Selector activeSelect={this.props.activeSelect} changeSelector={this.props.changeSelector} />);
	}
}

export default SelectContainer;