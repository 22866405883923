import React, {Component} from 'react';

import { Table, Row, Col } from 'react-bootstrap';

import { LinkContainer } from 'react-router-bootstrap';

const PLANKET_PRICE = 19.95;

class LineItem extends Component{
	render(){
		let {detail,material,detailImages,materialImages} = this.props;
		let prc = ' $19.95 ';
		//let info = material + " & " +detail;

		return (
		  <tr>
		    	<td>
		      	<small>{prc}</small>
		      </td>

		      <td>
		      	<img src={detailImages[detail]} alt="Detail pattern" style={{height: 100, width: 90, objectFit: "cover"}} />
		      </td>

		      <td>
		      	<img src={materialImages[material]} alt="Blanket" style={{height:100, width: 90, objectFit: "cover"} }/>
		      </td>

		      <td>
		      	<LinkContainer to="/create">
			      	<span style={{margin:4}} size="xs" variant="secondary" onClick={()=>{ this.props.edit(this.props.itemIndex) }}>
								<i className="material-icons" style={{fontSize:'1em'}}>create</i>
							</span>
						</LinkContainer>

		      	<span size="xs" variant="danger" style={{margin:4}} onClick={()=>{ this.props.delete(this.props.itemIndex) }}>
		      		<i className="material-icons" style={{fontSize:'1em'}}>delete</i>
		      	</span>
		      </td>

		  </tr>
	  );
	}
}

class CheckoutInvoice extends Component{
	render(){
		let {availableMaterials,availableDetails,checkoutItems} = this.props;
		let materialImages = {};
		let detailImages = {};
		availableDetails.forEach((ad)=>{
			detailImages[ad.id] = ad.image;
		})
		availableMaterials.forEach((am)=>{
			materialImages[am.id] = am.image;
		})
		let total = (((checkoutItems.length * PLANKET_PRICE) * 100 )/100).toFixed(2);
		// console.log("OUR MATERIAL:", materialImages);
		//detailImage,materialImage
		return (
			<Row>
				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
				{checkoutItems.length > 0 && (
				<Table responsive="sm">
					<thead>
						<tr>
							<th>Price</th>
							<th>Design</th>
							<th>Blanket</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
					{checkoutItems.map((f,ind)=>{
						return <LineItem detailImages={detailImages} materialImages={materialImages} {...f} key={ind} itemIndex={ind} delete={this.props.deleteItem} edit={this.props.editItem} />
					}
				)}
					</tbody>
				</Table>
				)}
				</Col>
				<div className="col-12">
					<p>Total: $<span>{total}</span></p>
				</div>
			</Row>
		);
	}
}

export default CheckoutInvoice;