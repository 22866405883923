import React, {Component} from 'react';

import { connect } from 'react-redux';

import MaterialSelector from './material';
import DetailSelector from './detail';
import SizeSelector from './size';
import DoneScreen from './done';

class Selector extends Component{
	render(){
		if(this.props.activeSelect==='material'){
			return <MaterialSelector />;
		}

		if(this.props.activeSelect==='detail'){
			return <DetailSelector />;
		}

		if(this.props.activeSelect==='size'){
			return <SizeSelector changeSelector={this.props.changeSelector} />;
		}
		
		if(this.props.activeSelect==='done'){
			return <DoneScreen changeSelector={this.props.changeSelector} />;
		}

	}
}

function mapStateToProps(state){
  return {
   userData: state.userData,
   currentDraft: state.currentDraft,
   finished: state.finished
  };
}

export default connect(mapStateToProps,null)(Selector);